/* eslint-disable no-unused-vars */
/* global */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
// import { Random } from 'meteor/random'
import { FaRandom, FaRegPlayCircle, FaRegPauseCircle } from 'react-icons/fa'
import { BsArrowRepeat } from "react-icons/bs";
import BBM from './utils/namespace'
// import { Session } from 'meteor/session'
import $ from 'jquery'
import VideoModal from './videoModal'
import IntervalProxy from './utils/intervalProxy'

export let timer = null
export let timerValue = 0
export let soundEffect = null

const WorkOutDetail = (props) => {

    const totalTime = props.minutes ? BBM.LocalStorage.get(BBM.Keys.Time, 20) : 0
    const soundFile = BBM.LocalStorage.get(BBM.Keys.Sound, '/sounds/laser-blast.mp3')
    const endSound = BBM.LocalStorage.get(BBM.Keys.EndSound, '/sounds/applause.mp3')
    const fifteenSound = BBM.LocalStorage.get(BBM.Keys.FifteenSound, '/sounds/fifteenSecond.mp3')
    const sCount = BBM.LocalStorage.get(BBM.Keys.SETCount, 5)
    let remainingTime = totalTime
    const [startDisabled, setStart] = useState(false)
    const [pauseDisabled, setPause] = useState(true)
    const [skipDisabled, setSkip] = useState(false)
    // const [timer, setTimer] = useState(null)
    // const [timerValue, setTimerValue] = useState(0)
    const [timerTextValue, setTimerText] = useState(`${remainingTime === 0 ? '00' : remainingTime}:00`)
    const [choice, setChoice] = useState(null)
    const [videoFile, setVideoFile] = useState(null)
    const [subTitle, setSubTitle] = useState('')
    const [timerMins, setTimerMins] = useState()
    const [isLoading, setLoading] = useState(false)

    // const overrideTime = (val) => {
    //     remainingTime = val
    //     setTimerText(`${remainingTime === 0 ? '00' : remainingTime}:00`)
    // }

    useEffect(() => {
        if (!choice) {
            if (props.minutes) {
                const c = 'EMOM' //BBM.randomChoice(['EMOM', 'AMRAP', 'EMOM', 'AMRAP', 'EMOM', 'AMRAP'])
                if (c === 'EMOM') {
                    setSubTitle('Every Minute On the Minute')
                    setTimerMins(props.minutes)
                }
                else {
                    setSubTitle('As Many Reps as Possible')
                    setTimerMins(props.minutes)
                }
                setChoice(c)
            }
            else {
                setTimerMins(0)
                setSubTitle('')
                const reps = Math.round(BBM.LocalStorage.get(BBM.Keys.SETReps, 10) * 1)
                setChoice(`${sCount} SETS of ${reps} Reps Each`)
            }
        }

        if (props.minutes) {
            timerValue = (60 * remainingTime)
        }
        else {
            timerValue = 0
        }

        if (!soundEffect) {
            soundEffect = new Audio();
        }

        setLoading(false)

    }, [props.data])

    const playVideo = (event) => {
        let suffix = ''
        if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
            suffix = '?showinfo=0&enablejsapi=1&origin=http://localhost:3000'
        }
        setVideoFile(`${event.target.attributes['value'].value}${suffix}`)
        setTimeout(() => {
            const popup = new Foundation.Reveal($('#video-modal')); // eslint-disable-line
            popup.open()
        }, 300)
    }

    const loadMovements = () => {
        setLoading(true)
        props.handleSkip()
    }

    const excercises = (data) => {
        const list = []

        if (!data) {
            return list
        }

        let reps = 20

        switch (choice) {
            case 'EMOM':
                reps = '45s' //BBM.LocalStorage.get(BBM.Keys.EMOMReps, 20)
                break
            case 'AMRAP':
                reps = BBM.LocalStorage.get(BBM.Keys.AMRAPReps, 10)
                break
            default:
                reps = Math.round(BBM.LocalStorage.get(BBM.Keys.SETReps, 10) * 1)
        }

        data.forEach((item) => {

            list.push(
                <tr key={item.id}>
                    <td align="center">
                        {
                            item.videoUrl ?
                                <a hre="#" value={item.videoUrl} onClick={playVideo}>Play</a>
                                : ''
                        }
                    </td>
                    <td align="center">{`${reps}`}</td>
                    <td align="left">{`${item.name}`}</td>
                </tr>
            )
        })
        return list
    }

    const touchStart = () => {
        soundEffect.src = '/sounds/laser-blast.mp3'
        soundEffect.muted = true
        soundEffect.volume = 0
        soundEffect.play()
    }

    const playFifteen = () => {
        soundEffect.src = fifteenSound
        soundEffect.muted = false
        soundEffect.volume = 1
        soundEffect.play()
    }

    const playSound = () => {
        // later on when you actually want to play a sound at any point without user interaction
        soundEffect.src = soundFile;
        soundEffect.muted = false
        soundEffect.volume = 1
        soundEffect.play();
    }
    const playEndSound = () => {
        // later on when you actually want to play a sound at any point without user interaction
        soundEffect.src = endSound;
        soundEffect.muted = false
        soundEffect.volume = 1
        soundEffect.play();
    }

    const timerCallBack = () => {

        const val = timerMins ? timerValue - 1 : timerValue + 1
        timerValue = val
        const secs = val % 60;
        const mins = parseInt(val / 60, 10);
        // const hours = parseInt(timerValue / 60 / 60, 10);
        // setTimerText(`${(hours < 10 ? '0' : '')}${hours}:${(mins < 10 ? '0' : '')}${mins}:${(secs < 10 ? '0' : '')}${secs}`)
        setTimerText(`${(mins < 10 ? '0' : '')}${mins}:${(secs < 10 ? '0' : '')}${secs}`)
        // if (secs < 1 && mins < 1 && hours < 1) {
        if (secs < 1 && mins < 1) {
            playEndSound()
            stopTimer()
            return
        }
        if (secs === 0) {
            playSound()
        }
        if (choice === 'EMOM') {
            // only play when there is 15 seconds of the current minute left
            if (secs === 15) {
                playFifteen()
            }
        }

    }

    const startTimer = () => {
        if (!timer) {
            timer = IntervalProxy.setTheInterval(timerCallBack, 1000)
            setStart(true)
            setPause(false)
            setSkip(true)
            setSkip(true)
        }
    }

    const stopTimer = () => {
        if (timer) {
            IntervalProxy.clearTheInterval(timer);
            // setTimer(null);
            timer = null
            timerValue = remainingTime * 60
            setStart(false)
            setPause(true)
            setSkip(false)
            setSkip(false)
        }
    }

    const pauseTimer = () => {
        if (timer) {
            IntervalProxy.clearTheInterval(timer);
            // setTimer(null);
            timer = null
            setStart(false)
            setSkip(false)
            setPause(true)
        }
    }

    const handleStop = () => {
        stopTimer()
        props.handleStop()
    }

    return (
        <div className="grid-x">
            <div className="cell small-12">
                <div id="setType" style={{ width: '100%', textAlign: 'center', fontSize: '1.5em' }}>{choice}</div>
                <div id="subTitle" style={{ width: '100%', textAlign: 'center', fontSize: '.8em', marginBottom: '12px' }}>
                    {subTitle}
                </div>

                <table className="movement-table">
                    <thead>
                        <tr>
                            <th>Video</th>
                            <th>{choice === 'EMOM' ? 'Time' : 'Reps'}</th>
                            <th>Movement</th>
                        </tr>
                    </thead>
                    <tbody>
                        {excercises(props.data, choice)}
                    </tbody>
                </table>
            </div>
            {
                (totalTime > 0) ?
                    <div className="cell small-12">
                        <div id="clock" style={{ width: '100%', marginTop: '7px', marginBottom: '0px', fontSize: '2.25em' }}>{timerTextValue}</div>
                        <div id="timeDesc" style={{ width: '100%', marginTop: '-12px', marginBottom: '21px', fontSize: '.8em' }}>Time Remaining</div>
                        <button type='button' id="skipBtn" className='button primary small radius' disabled={skipDisabled} style={{ marginRight: '7px' }} onClick={loadMovements}>
                            New List {isLoading ? <BsArrowRepeat className="spinning" style={{ position: 'relative', top: '3px' }} />
                                : <FaRandom style={{ position: 'relative', top: '3px' }} />
                            }
                        </button>                        <button type='button' id="startBtn" className='button success small radius' disabled={startDisabled} style={{ marginRight: '7px' }} onClick={startTimer} onTouchStart={touchStart}>Start <FaRegPlayCircle style={{ position: 'relative', top: '3px' }} /></button>
                        <button type='button' id="pauseBtn" className='button secondary small radius' disabled={pauseDisabled} style={{ marginRight: '7px' }} onClick={pauseTimer}>Pause <FaRegPauseCircle style={{ position: 'relative', top: '3px' }} /></button>
                        <button type='button' id="cancelBtn" className='button secondary small radius' onClick={handleStop}>Cancel </button>
                    </div>
                    : <div className="cell small-10 small-offset-1">
                        <div id="clock" style={{ width: '100%', marginTop: '7px', marginBottom: '0px', fontSize: '2.25em' }}>{timerTextValue}</div>
                        <div id="timeDesc" style={{ width: '100%', marginTop: '-12px', marginBottom: '21px', fontSize: '.8em' }}>Elapsed Time</div>

                        <button type='button' id="skipBtn" className='button primary small radius' disabled={skipDisabled} style={{ marginRight: '7px' }} onClick={loadMovements}>
                            New List {isLoading ? <BsArrowRepeat className="spinning" style={{ position: 'relative', top: '3px' }} />
                                : <FaRandom style={{ position: 'relative', top: '3px' }} />
                            }
                        </button>
                        <button type='button' id="startBtn" className='button success small radius' disabled={startDisabled} style={{ marginRight: '7px' }} onClick={startTimer} onTouchStart={touchStart}>Start <FaRegPlayCircle style={{ position: 'relative', top: '3px' }} /></button>
                        <button type='button' id="pauseBtn" className='button secondary small radius' disabled={pauseDisabled} style={{ marginRight: '7px' }} onClick={pauseTimer}>Pause <FaRegPauseCircle style={{ position: 'relative', top: '3px' }} /></button>
                        <button type='button' id="cancelBtn" className='button secondary small radius' onClick={handleStop}>Cancel</button>
                    </div>
            }
            <VideoModal video={videoFile} />
        </div>
    )
}

export default WorkOutDetail
