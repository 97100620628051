import { API } from 'aws-amplify'

export class DataManager {

    static async getMovements({ dumbBell, movements, lastWorkout, timed, hiddenItems }) {
        const response = await API.post('bbm', '/movements', {
            body: { dumbBell, movements, lastWorkout, timed, hiddenItems }
        })
        return response
    }

    static async getAllMovements({ hidden, searchVal }) {
        const response = await API.post('bbm', '/allmovements', {
            body: { hidden, searchVal }
        })
        return response
    }

}
