import React from 'react';
import Landing from './landing'
import Navigation from './navigation'
import BBM from './utils/namespace'
import { bbmStore } from './utils/bbmStore'

const Home = () => {

    const [storeState, updateStore] = bbmStore.usePureStore()

    const backgroundClass = () => {
        const className = storeState.backgroundImageClass
        return `${className} react-target animate__animated animate__fadeIn`
    }

    return (
        <div className={backgroundClass()}>
            <div className="row">
                <div className="twelve columns text-center" style={{ padding: '12px' }}>
                    <Landing />
                    <Navigation />
                </div>
            </div>
        </div>
    )
}

export default Home;
