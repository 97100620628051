import createStore from "pure-store/react"
import BBM from './namespace'

export const bbmStore = createStore({
    showPreferences: false,
    hiddenItems: BBM.LocalStorage.get(BBM.Keys.hiddenItems, [], true),
    backgroundImageClass: BBM.LocalStorage.get(BBM.Keys.BackgroundClass, 'default-background')
})

export const showPreferencesModal = () => {
    bbmStore.update((s) => {
        s.showPreferences = true
    })
}

export const hidePreferencesModal = () => {
    bbmStore.update((s) => {
        s.showPreferences = false
    })
}

export const appendHidden = (item) => {
    bbmStore.update((s) => {
        s.hiddenItems.push(item)
        BBM.LocalStorage.set(BBM.Keys.hiddenItems, s.hiddenItems, true)
    })
}

export const removeHidden = (item) => {
    bbmStore.update((s) => {
        const idx = s.hiddenItems.indexOf(item)
        if (idx !== -1) {
            s.hiddenItems.splice(idx, 1)
        }
        BBM.LocalStorage.set(BBM.Keys.hiddenItems, s.hiddenItems, true)
    })
}

export const updateBackgroundImageClass = (value) => {
    bbmStore.update((s) => {
        s.backgroundImageClass = value
        BBM.LocalStorage.set(BBM.Keys.BackgroundClass, value)
    })
}