/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player'

// eslint-disable-next-line no-unused-vars
const VideoModal = (props) => {

    const [playing, setPlaying] = useState(true)

    useEffect(() => {
        setPlaying(true)
        return () => {
            setPlaying(false)
        }
    })

    const stopPlaying = () => {
        setPlaying(false)
    }

    return (
        <div className="reveal nav-modal animated slideInDown" id="video-modal" data-reveal="" data-overlay="false" data-close-on-click="true">
            <div className="grid-container">
                <div className="grid-x" style={{ height: '30px' }}>
                    <div className="cell small-12 text-right">
                        <button id="closeBtn" className="close-button" data-close="" onClick={stopPlaying} aria-label="Close modal" type="button">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
                <div className="grid-x" style={{ marginBottom: '30px' }}>
                    <div className="cell small-12">
                        <ReactPlayer key={props.video} width="95%" url={props.video} style={{ margin: 'auto' }} playing={playing} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VideoModal;