const IntervalProxy = {}

IntervalProxy.clearTheInterval = (timer) => {
    clearInterval(timer);
}

IntervalProxy.setTheInterval = (func, amount) => {
    return setInterval(func, amount);
}

export default IntervalProxy
