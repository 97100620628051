import React from 'react';
import {
    Route,
    Routes
} from "react-router-dom"

import Home from './home'
import Movements from './movements'

const App = () => {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/movements" element={<Movements />} />
        </Routes>
    )
}

export default App;
