/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import BBM from './utils/namespace'
// import { _ } from 'lodash'
import Modal from 'react-foundation-modal'
import { bbmStore, updateBackgroundImageClass } from './utils/bbmStore'
import { FaTools } from 'react-icons/fa'

export let soundEffect = null
export let soundPlayed = false

let cache = {}

const toProperCase = (val) => val.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); })

const importAll = (r) => {
    r.keys().forEach((key) => (cache[key] = r(key)))
}

// https://stackoverflow.com/questions/42118296/dynamically-import-images-from-a-directory-using-webpack
// Note from the docs -> Warning: The arguments passed to require.context must be literals!
importAll(require.context("./sounds", false, /\.(mp3)$/))

let i = 0

const soundList = Object.entries(cache).map((module) => {
    const obj = {}
    obj.index = i
    const name = module[0].replace("./", "")
    obj.name = toProperCase(name.replace(/-/g, ' ').split('.')[0])
    obj.value = module[1].default
    i += 1
    return obj
})

const PreferencesModal = () => {

    const [storeState, storeUpdate] = bbmStore.usePureStore()

    const sTime = BBM.LocalStorage.get(BBM.Keys.Time, 20)
    const eReps = BBM.LocalStorage.get(BBM.Keys.EMOMReps, 5)
    const aReps = BBM.LocalStorage.get(BBM.Keys.AMRAPReps, 10)
    const sReps = BBM.LocalStorage.get(BBM.Keys.SETReps, 20)
    const selectedSound = BBM.LocalStorage.get(BBM.Keys.Sound, '/sounds/laser-blast.mp3')
    const sCount = BBM.LocalStorage.get(BBM.Keys.SETCount, 5)
    const eSound = BBM.LocalStorage.get(BBM.Keys.EndSound, '/sounds/applause.mp3')
    const fifSound = BBM.LocalStorage.get(BBM.Keys.FifteenSound, '/sounds/snare.mp3')
    const sMovements = BBM.LocalStorage.get(BBM.Keys.SETMovements, 4)
    const tMovements = BBM.LocalStorage.get(BBM.Keys.TimedMovements, 4)
    // const defBackground = BBM.LocalStorage.get(BBM.Keys.BackgroundClass, 'default-background')
    const defBackground = storeState.backgroundImageClass

    const [sessionTime, setSessionTime] = useState(sTime);
    const [emomReps, setEmomReps] = useState(eReps);
    const [amrapReps, setAmrapReps] = useState(aReps);
    const [setReps, setSetReps] = useState(sReps);
    const [soundFile, setSoundFile] = useState(selectedSound);
    const [sounds, setSounds] = useState(null);
    const [numberOfSets, setNumberOfSets] = useState(sCount);
    const [endSound, setEndSound] = useState(eSound);
    const [fifteenSound, setFifteenSound] = useState(fifSound);
    const [numberOfMovements, setNumberOfMovements] = useState(sMovements);
    const [numberOfTimedMovements, setNumberOfTimedMovements] = useState(tMovements);
    const [bgClass, setBGClass] = useState(defBackground);
    const [errMessage, setErrMessage] = useState('');


    useEffect(() => {

        setSounds(soundList)

        if (!soundEffect) {
            soundEffect = new Audio()
        }

    }, [])

    const timeChange = (event) => {
        setSessionTime(event.target.value)
    }

    const emomChange = (event) => {
        setEmomReps(event.target.value)
    }

    const amrapChange = (event) => {
        setAmrapReps(event.target.value)
    }

    const setChange = (event) => {
        setSetReps(event.target.value)
    }

    const numberOfSetsChange = (event) => {
        setNumberOfSets(event.target.value)
    }

    const numberOfMovementsChange = (event) => {
        if (Number(event.target.value) < 1 || Number(event.target.value) > 12) {
            setErrMessage('# SET movements must be between 1 and 12!')
            return
        }
        setErrMessage('')
        setNumberOfMovements(event.target.value)
    }

    const numberOfTimedMovementsChange = (event) => {
        if (Number(event.target.value) < 1 || Number(event.target.value) > 12) {
            setErrMessage('# Timed movements must be between 1 and 12!')
            return
        }
        setErrMessage('')
        setNumberOfTimedMovements(event.target.value)
    }

    function saveAndClose() {
        BBM.LocalStorage.set(BBM.Keys.Time, sessionTime)
        BBM.LocalStorage.set(BBM.Keys.EMOMReps, emomReps)
        BBM.LocalStorage.set(BBM.Keys.AMRAPReps, amrapReps)
        BBM.LocalStorage.set(BBM.Keys.SETReps, setReps)
        BBM.LocalStorage.set(BBM.Keys.Sound, soundFile)
        BBM.LocalStorage.set(BBM.Keys.SETCount, numberOfSets)
        BBM.LocalStorage.set(BBM.Keys.EndSound, endSound)
        BBM.LocalStorage.set(BBM.Keys.FifteenSound, fifteenSound)
        BBM.LocalStorage.set(BBM.Keys.SETMovements, numberOfMovements)
        BBM.LocalStorage.set(BBM.Keys.TimedMovements, numberOfTimedMovements)
        // BBM.LocalStorage.set(BBM.Keys.BackgroundClass, bgClass)
        updateBackgroundImageClass(bgClass)
        hideModal()
        // if (!Meteor.isAppTest) {
        //     window.location.reload();
        // }
    }

    const playSound = (source) => {
        soundEffect.volume = 1
        soundEffect.muted = false
        soundEffect.src = source
        return soundEffect.play()
    }

    const touchStart = () => {
        soundEffect.src = '/sounds/laser-blast.mp3'
        soundEffect.muted = true
        soundEffect.volume = 0
        soundEffect.play()
    }

    const setAudio = (event) => {
        setSoundFile(event.target.value)
        if (event.target.value === '') {
            return
        }
        playSound(event.target.value)
    }

    const setEndAudio = (event) => {
        setEndSound(event.target.value)
        if (event.target.value === '') {
            return
        }
        playSound(event.target.value)
    }
    const setFifteenAudio = (event) => {
        setFifteenSound(event.target.value)
        if (event.target.value === '') {
            return
        }
        playSound(event.target.value)
    }

    function setBackground(event) {
        // alert('hi')
        if (event.target.value === '') {
            setBGClass('default-background')
        }
        else {
            setBGClass(event.target.value)
        }
    }

    const hideModal = () => {
        storeUpdate({ showPreferences: false })
    }

    const soundOptions = (suffix) => {
        const list = []

        list.push(<option key='0' value="">No Alert</option>)

        if (!sounds) {
            return list
        }

        if (sounds.length === 0) {
            return []
        }

        sounds.forEach((s) => {
            const idx = `${s.index}-${suffix}`
            list.push(<option key={idx} value={s.value}>{s.name}</option>)
        })

        return list
    }

    return (

        <Modal
            open={storeState.showPreferences}
            closeModal={hideModal}
            isModal={true}
            size="small animate__animated animate__zoomIn"
        >
            <div className="grid-container">
                <div className="grid-x" >
                    <div className="small-12 cell">
                        <div className="grid-x grid-padding-x">
                            <div style={{ width: '100%', marginTop: '30px' }}>
                                <div className="preferences-title"><FaTools style={{ color: '#990000', fontSize: '1.5em', marginRight: '9px' }} /><div style={{ position: 'relative', display: 'inline', top: '-5px', fontSize: '1.2em' }}>Preferences</div></div>
                                <button id="btnClose" className="close-button float-right" aria-label="Close modal" type="button" onClick={hideModal}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>

                            <div className="small-12 cell">
                                <div className="grid-x">
                                    <div className="cell small-6 large-5 text-right" style={{ paddingRight: '7px' }}>
                                        <label>Time Limit (Mins):</label>
                                    </div>
                                    <div className="cell small-6 large-7 left">
                                        <input id="timeLimit" type="number" placeholder="20" onChange={timeChange} value={sessionTime} />
                                    </div>
                                </div>
                            </div>
                            <div className="small-12 cell">
                                <div className="grid-x">
                                    <div className="cell small-6 large-5 text-right" style={{ paddingRight: '7px' }}>
                                        <label className="right">EMOM Reps:</label>
                                    </div>
                                    <div className="cell small-6 large-7 left">
                                        <input id="emomReps" type="number" placeholder="5" onChange={emomChange} value={emomReps} />
                                    </div>
                                </div>
                            </div>
                            <div className="small-12 cell">
                                <div className="grid-x">
                                    <div className="cell small-6 large-5 text-right" style={{ paddingRight: '7px' }}>
                                        <label className="right">AMRAP Reps:</label>
                                    </div>
                                    <div className="cell small-6 large-7 left">
                                        <input type="number" placeholder="10" onChange={amrapChange} value={amrapReps} />
                                    </div>
                                </div>
                            </div>

                            <div className="small-12 cell">
                                <div className="grid-x">
                                    <div className="cell small-6 large-5 text-right" style={{ paddingRight: '7px' }}>
                                        <label className="right">SET Reps:</label>
                                    </div>
                                    <div className="cell small-6 large-7 left">
                                        <input id="setReps" type="number" placeholder="5" onChange={setChange} value={setReps} />
                                    </div>
                                </div>
                            </div>

                            <div className="small-12 cell">
                                <div className="grid-x">
                                    <div className="cell small-6 large-5 text-right" style={{ paddingRight: '7px' }}>
                                        <label className="right">SET Count:</label>
                                    </div>
                                    <div className="cell small-6 large-7 left">
                                        <input id="setCount" type="number" placeholder="5" onChange={numberOfSetsChange} value={numberOfSets} />
                                    </div>
                                </div>
                            </div>

                            <div className="small-12 cell">
                                <div className="grid-x">
                                    <div className="cell small-6 large-5 text-right" style={{ paddingRight: '7px' }}>
                                        <label className="right"># SET Movements:</label>
                                    </div>
                                    <div className="cell small-6 large-7 left">
                                        <input id="setMovements" type="number" placeholder="4" onChange={numberOfMovementsChange} value={numberOfMovements} />
                                    </div>
                                </div>
                            </div>

                            <div className="small-12 cell">
                                <div className="grid-x">
                                    <div className="cell small-6 large-5 text-right" style={{ paddingRight: '7px' }}>
                                        <label className="right"># Timed Movements:</label>
                                    </div>
                                    <div className="cell small-6 large-7 left">
                                        <input id="timedMovements" type="number" placeholder="4" onChange={numberOfTimedMovementsChange} value={numberOfTimedMovements} />
                                    </div>
                                </div>
                            </div>

                            <div className="small-12 cell">
                                <div className="grid-x">
                                    <div className="cell small-6 large-5 text-right" style={{ paddingRight: '7px' }}>
                                        <label className="right">Custom Background:</label>
                                    </div>
                                    <div className="cell small-6 large-7 left">
                                        <select id="backgroundSelect" onChange={setBackground} value={bgClass}>
                                            <option value="">Default Background</option>
                                            <option value="all-cogged-up">All Cogged Up</option>
                                            <option value="cant-touch-this">Cant Touch This</option>
                                            <option value="cloudy-day">Cloudy Day</option>
                                            <option value="circuit-breaker">Circuit Breaker</option>
                                            <option value="domino">Domino</option>
                                            <option value="parquet">Parquet</option>
                                            <option value="patchwork">Patchwork</option>
                                            <option value="polka-dot">Polka Dot</option>
                                            <option value="puzzle">Puzzle</option>
                                            <option value="road-runner">Road Runner</option>
                                            <option value="spinal-tap">Spinal Tap</option>
                                            <option value="squared-off">Squared Off</option>
                                            <option value="velvet">Velvet</option>
                                            <option value="eighty-eight-keys">88 Keys</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="small-12 cell">
                                <div className="grid-x">
                                    <div className="cell small-6 large-5 text-right" style={{ paddingRight: '7px' }}>
                                        <label className="right">Per Minute Sound:</label>
                                    </div>
                                    <div className="cell small-6 large-7 left">
                                        <select id="audioSelect" onChange={setAudio} onTouchStart={touchStart} value={soundFile}>
                                            {soundOptions(1)}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="small-12 cell">
                                <div className="grid-x">
                                    <div className="cell small-6 large-5 text-right" style={{ paddingRight: '7px' }}>
                                        <label className="right">15 Second Sound:</label>
                                    </div>
                                    <div className="cell small-6 large-7 left">
                                        <select id="audioSelect15" onChange={setFifteenAudio} onTouchStart={touchStart} value={fifteenSound}>
                                            {soundOptions(2)}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="small-12 cell">
                                <div className="grid-x">
                                    <div className="cell small-6 large-5 text-right" style={{ paddingRight: '7px' }}>
                                        <label className="right">End Of Set Sound:</label>
                                    </div>
                                    <div className="cell small-6 large-7 left">
                                        <select id="audioSelectEnd" onChange={setEndAudio} onTouchStart={touchStart} value={endSound}>
                                            {soundOptions(3)}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div id="errorMsg" style={{ minHeight: '0px', width: '100%', color: '#990000', textAlign: 'center' }}>
                                {errMessage}
                            </div>

                            <div className="small-12 cell text-center">
                                <button id="btnSave" type='button' className="button primary small radius" data-close="" onClick={saveAndClose}>Save Preferences</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <audio id="laserBlast" src='/sounds/laser-blast.mp3' type="audio/mpeg" />
        </Modal>
    )
}

export default PreferencesModal