import React from 'react';
import Navigation from './navigation'
import BBM from './utils/namespace'
import MovementList from './movementList'

const Movements = () => {

    const backgroundClass = () => {
        const className = BBM.LocalStorage.get(BBM.Keys.BackgroundClass, 'default-background')
        return `${className} react-target animate__animated animate__fadeIn`
    }

    return (
        <div className={backgroundClass()}>
            <div className="row">
                <div className="twelve columns text-center" style={{ padding: '12px', minWidth: '300px' }}>
                    <MovementList />
                    <Navigation />
                </div>
            </div>
        </div>
    )
}

export default Movements;