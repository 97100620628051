import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from "react-router-dom";
import { FaEllipsisH } from 'react-icons/fa'
import { useDetectOutsideClick } from './utils/useDetectOutsideClick';

// import { bbmStore } from './utils/bbmStore'

const DropdownMenu = ({ showPreferences }) => {
    const dropdownRef = useRef(null);
    const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
    const onClick = () => setIsActive(!isActive);
    let navigate = useNavigate();

    const showPrefs = () => {
        setIsActive(false)
        showPreferences()
    }

    const showMoves = () => {
        setIsActive(false)
        navigate(`/movements`);
    }

    const showHome = () => {
        setIsActive(false)
        navigate(`/`);
    }

    return (
        <div className="menu-container ">
            <button onClick={onClick} className="menu-trigger">
                <span><FaEllipsisH style={{ fontSize: '1.2em' }} /></span>
            </button>
            <nav ref={dropdownRef} className={`menu ${isActive ? 'active' : 'inactive'}`}>
                <ul>
                    <li className="li-border li-top"><a href="#" onClick={showHome}>Configure Workout</a></li>
                    <li className="li-border"><a href="#" onClick={showPrefs}>Preferences</a></li>
                    <li className="li-bottom"><a onClick={showMoves}>Manage Movements</a></li>
                </ul>
            </nav>
        </div>
    )
}

DropdownMenu.propTypes = {
    showPreferences: PropTypes.func.isRequired,
    showMovements: PropTypes.func.isRequired
}

export default DropdownMenu