
const BBM = {}

BBM.Keys = {
    Time: 'session-time',
    EMOMReps: 'emom-reps',
    AMRAPReps: 'amrap-reps',
    SETReps: 'set-reps',
    Sound: 'sound-file',
    SETCount: 'number-of-sets',
    EndSound: 'end-sound',
    FifteenSound: 'fifteen-sound',
    VideoFile: 'video-file',
    SETMovements: 'set-movements',
    TimedMovements: 'timed-movements',
    BackgroundClass: 'background-class',
    LastWorkout: 'last-workout',
    hiddenItems: 'hidden-items'

}

BBM.LocalStorage = {
    set: function (key, value, isJson = false) {
        const val = isJson ? JSON.stringify(value) : value
        localStorage.setItem(key, val)
    },
    get: function (key, defaultValue = '', isJson = false) {
        const defaultRetVal = defaultValue || ''

        const val = localStorage.getItem(key) || defaultRetVal

        if (isJson && val !== '') {
            try {
                return JSON.parse(val)
            }
            catch {
                return defaultValue
            }
        }

        return val
    }
}

BBM.randomChoice = (choices) => {
    var index = Math.floor(Math.random() * choices.length);
    return choices[index];
}

export default BBM