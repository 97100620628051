/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React from 'react'
import PreferencesModal from './preferencesModal'
import { bbmStore } from './utils/bbmStore'
import DropdownMenu from './dropdownMenu'

const Navigation = () => {
    const [storeState, storeUpdate] = bbmStore.usePureStore()

    const showPreferences = () => {
        storeUpdate({ showPreferences: true })
    }

    const showMovements = () => {
        alert('showing the movements')
    }

    return (
        <div className="grid-container" >
            <div className="grid-x stick-to-top navigation-bar" style={{ padding: '7px', margin: '0', width: '100%' }}>
                <div className="cell small-10">
                    <div style={{ padding: '7px', width: '100%', textAlign: 'left' }}>
                        <img src="/favicon-32x32.png" alt="" />
                        <div style={{ position: 'relative', top: '7px', display: 'inline-block', fontSize: '1.5em', color: '#222', marginLeft: '7px', fontStyle: 'italic' }}>Body By Me!</div>
                    </div>
                </div>
                <div className="cell small-2 text-right">
                    <DropdownMenu showPreferences={showPreferences} showMovements={showMovements} />
                    {/* <a href="#" style={{ position: 'relative', top: '20px', marginRight: '21px' }} onClick={showTheModal}><FaEllipsisH style={{ fontSize: '2em', color: '#444' }} /></a> */}
                </div>
            </div>
            <PreferencesModal />
        </div>
    )

}

export default Navigation

// <p><button class="button" data-open="exampleModal1">Click me for a modal</button></p>