/* eslint-disable react/prop-types */
import React from 'react'

const Toggle = (props) => (

    <div style={{ width: '100%' }}>
        <div style={{ width: '250px', textAlign: 'left', marginLeft: 'auto', marginRight: 'auto' }}>
            <div className="switch small" style={{ display: 'inline-block', marginRight: '7px' }}>
                <input className="switch-input" id={props.id} type="checkbox" checked={props.value} name={props.id} onChange={props.handleChange} />
                <label className="switch-paddle radius" htmlFor={props.id}>
                </label>
            </div>
            <div id="labelDiv" style={{ display: 'inline-block', position: 'relative', top: '-7px' }}>
                {props.label}
            </div>
        </div>
    </div>

)

export default Toggle