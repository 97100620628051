import React from 'react';
import ReactDOM from 'react-dom';
import 'foundation-sites/dist/css/foundation.min.css'
import 'animate.css'
import './index.css';
import './main.scss'
import './backgrounds.scss'
import { Amplify } from 'aws-amplify'
import config from './config';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'foundation-sites'
import $ from 'jquery'
import { BrowserRouter } from "react-router-dom"

Amplify.configure({
    // Auth: {
    //     mandatorySignIn: true,
    //     region: config.cognito.REGION,
    //     userPoolId: config.cognito.USER_POOL_ID,
    //     identityPoolId: config.cognito.IDENTITY_POOL_ID,
    //     userPoolWebClientId: config.cognito.APP_CLIENT_ID
    // },
    Storage: {
        region: config.s3.REGION,
        bucket: config.s3.BUCKET
        // identityPoolId: config.cognito.IDENTITY_POOL_ID
    },
    API: {
        endpoints: [
            {
                name: "bbm",
                endpoint: config.apiGateway.URL,
                region: config.apiGateway.REGION
            },
        ]
    }
});


ReactDOM.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>, document.getElementById('react-root')
);

$(document).foundation();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
