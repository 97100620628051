/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useCallback } from 'react'
import { IoWaterSharp, IoEyeOutline, IoEyeOffOutline } from 'react-icons/io5'
import { FaArrowCircleUp, FaArrowCircleDown, FaCheck } from 'react-icons/fa'
import { DataManager } from './utils/dataManager'
import { Oval } from 'react-loader-spinner'
import { bbmStore, appendHidden, removeHidden } from './utils/bbmStore'
import { debounce } from "lodash";

export default function list() {

    // eslint-disable-next-line no-unused-vars
    const [storeState, updateStore] = bbmStore.usePureStore()

    // const [videoFile, setVideoFile] = useState(null)
    const [page, setPage] = useState(0)
    const [movements, setMovements] = useState([])
    const pageSize = 8
    const [pages, setPages] = useState(1)
    const [loading, setLoading] = useState(true)
    const [searchVal, setSearchVal] = useState('')

    const searchHandler = useCallback(debounce((val) => {
        setSearchVal(val)
    }, 350, []))

    const getData = async () => {
        let p
        let list
        setPage(0)
        setPages(0)
        try {
            setLoading(true)
            list = await DataManager.getAllMovements({ hidden: storeState.hiddenItems, searchVal })
            p = Math.ceil(list.length / pageSize) - 1
        }
        finally {
            setPages(p)
            setMovements(list)
            setLoading(false)
        }
    }

    useEffect(async () => {
        await getData()

    }, [searchVal])

    // const playVideo = (event) => {
    //     let suffix = ''
    //     if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
    //         suffix = '?showinfo=0&enablejsapi=1&origin=http://localhost:3000'
    //     }
    //     setVideoFile(`${event.target.attributes['value'].value}${suffix}`)
    //     setTimeout(() => {
    //         const popup = new Foundation.Reveal($('#video-modal')); // eslint-disable-line
    //         popup.open()
    //     }, 300)
    // }

    const prevPage = () => {
        let newPage = page
        if (newPage < 0) {
            newPage = 0
        }
        else {
            newPage = page - 1
        }
        setPage(newPage)
    }

    const nextPage = () => {

        if (page >= pages) {
            return
        }

        let newPage = page + 1

        setPage(newPage)
    }

    const hideItem = (e) => {
        const id = e.currentTarget.dataset.itemid || ''
        const idx = e.currentTarget.dataset.index || -1

        const copy = [...movements]

        if (id !== '') {
            appendHidden(id)
            if (idx !== -1) {
                copy[idx].hidden = true
            }
        }

        setMovements(copy)

    }

    const unHideItem = (e) => {
        const id = e.currentTarget.dataset.itemid || ''
        const idx = e.currentTarget.dataset.index || -1

        const copy = [...movements]

        const hiddenIdx = storeState.hiddenItems.indexOf(id)

        if (id !== '' && idx !== -1 && hiddenIdx !== -1) {
            removeHidden(id, 1)
            copy[idx].hidden = false
        }
        setMovements(copy)
    }

    const movementItems = () => {
        if (movements === 'undefined' || !movements) {
            return []
        }

        const list = []

        const start = page * pageSize
        const end = start + pageSize

        let i = start

        for (i; i < end; i++) {

            if (i > movements.length - 1) {
                break
            }

            list.push(<tr key={movements[i].id}>
                <td align="left">
                    {movements[i].name}
                </td>
                <td>
                    {movements[i].focusArea}
                </td>
                <td align="center">
                    {movements[i].emomWorthy ? <FaCheck style={{ color: '#555' }} /> : ''}
                </td>
                <td align="center">
                    {movements[i].requiresDumbbell ? <FaCheck style={{ color: '#555' }} /> : ''}
                </td>
                {/* <td>
                    <td align="center">
                        {
                            movements[i].videoUrl ?
                                <a hre="#" value={movements[i].videoUrl} onClick={playVideo}><FaPlayCircle /></a>
                                : ''
                        }
                    </td>
                </td> */}
                <td align="center">
                    {movements[i].hidden ?
                        <button href="#" data-index={i} data-itemid={movements[i].id} onClick={unHideItem}><span aria-hidden="true">
                            <IoEyeOffOutline style={{ color: '#990000', fontSize: '1.2em' }} />
                        </span></button>
                        :
                        <button href="#" data-index={i} data-itemid={movements[i].id} onClick={hideItem}><span aria-hidden="true" >
                            <IoEyeOutline style={{ color: '#ccc', fontSize: '1.2em' }} />
                        </span></button>
                    }
                </td>
            </tr>)
        }

        return list

    }

    const prevStyle = () => {
        let color = '#990000'

        if (page <= 0) {
            color = '#666'
        }

        return { fontSize: '1.5em', color }
    }

    const nextStyle = () => {
        let color = '#990000'

        if (page >= pages) {
            color = '#666'
        }

        return { fontSize: '1.5em', color }
    }

    const previousDisabled = () => {
        return page <= 0
    }

    const nextDisabled = () => {
        return page >= pages
    }

    const doSearch = (e) => {
        searchHandler(e.currentTarget.value)
    }

    return (
        <div className="grid-x" >

            <div className="cell small-12 medium-10 large-6 dialogContainer undernav" style={{ padding: '7px', minHeight: '75vh' }}>

                <div className="cell small-12" style={{ width: '100%', marginTop: '30px' }}>
                    <div className="preferences-title"><IoWaterSharp style={{ color: '#990000', fontSize: '1.5em', marginRight: '9px' }} /><div style={{ position: 'relative', display: 'inline', top: '-5px', fontSize: '1.2em' }}>Movement Management</div></div>
                    <button id="btnNext" className="float-right" aria-label="Prev" type="button" disabled={nextDisabled()} onClick={nextPage} style={{ position: 'relative', display: 'inline', top: '-27px' }}>
                        <span aria-hidden="true"><FaArrowCircleDown style={nextStyle()} /></span>
                    </button>
                    <button id="btnPrev" className="float-right" aria-label="Next" type="button" disabled={previousDisabled()} onClick={prevPage} style={{ position: 'relative', display: 'inline', top: '-27px', marginRight: '27px' }}>
                        <span aria-hidden="true"><FaArrowCircleUp style={prevStyle()} /></span>
                    </button>
                </div>

                <div className="cell small-12" style={{ marginBottom: '12px' }}>
                    <input type="text" placeholder="Search Movements" onChange={doSearch} style={{ margin: '0 auto' }} />
                </div>

                {loading ? <Oval
                    ariaLabel="loading-indicator"
                    height={100}
                    width={100}
                    strokeWidth={5}
                    color="#990000"
                    secondaryColor="#efefef"
                    wrapperClass="verticalCenter"
                /> :
                    <>
                        <table className="movement-table" style={{ border: '1px solid #ccc', borderRadius: '8px' }}>
                            <thead>
                                <tr style={{ fontSize: '.8em' }}>
                                    <th align="left" width="50%">Movement</th>
                                    <th align="left">Focus</th>
                                    <th align="center">EMOM</th>
                                    <th align="center">Dumbbell</th>
                                    {/* <th align="center" style={{ fontSize: '.8em' }}>Play<br />Video</th> */}
                                    <th align="center">Toggle<br />Visible</th>
                                </tr>
                            </thead>
                            <tbody>
                                {movementItems(movements)}
                            </tbody>
                        </table>
                        <div>Page {page + 1} of {pages + 1} </div>
                    </>
                }
            </div>

        </div>

    )
}