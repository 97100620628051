/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import Toggle from './toggle'
import { FaAngleDoubleRight } from 'react-icons/fa';
import { BsArrowRepeat } from "react-icons/bs";
// import BeatLoader from "react-spinners/BeatLoader";
import WorkOutDetail from './workOutDetail'
import BBM from './utils/namespace'
import { DataManager } from './utils/dataManager'
import { bbmStore } from './utils/bbmStore'


const Landing = () => {

    const [storeState, updateStore] = bbmStore.usePureStore()
    const movements = BBM.LocalStorage.get(BBM.Keys.SETMovements, 4)
    const timedMovements = BBM.LocalStorage.get(BBM.Keys.TimedMovements, 4)
    // const workout = BBM.LocalStorage.get(BBM.Keys.LastWorkout, [])

    // const [kettleBall, setKettleBall] = useState(false);
    const [dumbBell, setDumbBell] = useState(false);
    const [timed, setTimed] = useState(false);
    const [minutes, setMinutes] = useState(0);
    const [workOut, setWorkout] = useState(null);
    const [errMessage, setError] = useState('');
    const [isLoading, setLoading] = useState(false)

    const handleGo = async () => {
        let moveCount = 0
        if (timed) {
            moveCount = Number(timedMovements)
        }
        else {
            moveCount = Number(movements)
        }

        try {
            setLoading(true)
            const response = await DataManager.getMovements({ dumbBell, movements: moveCount, lastWorkout: workOut || [], timed, hiddenItems: storeState.hiddenItems })
            setWorkout(response)
        }
        catch (err) {
            console.error('fetch error', err)
            setWorkout(null)
        }
        finally {
            setLoading(false)
        }
    }

    // const handleKettleball = (val) => {
    //     if (val.target.checked) {
    //         setTimed(false)
    //         setMinutes(0)
    //     }
    //     setKettleBall(val.target.checked)
    // }

    const handleDumbbell = (val) => {
        if (val.target.checked) {
            setTimed(false)
            setMinutes(0)
        }
        setDumbBell(val.target.checked)
    }

    const handleTimed = (val) => {
        if (val.target.checked) {
            // setKettleBall(false)
            setDumbBell(false)
            setMinutes(20)
            setTimed(true)
        }
        else {
            setMinutes(0)
            setTimed(false)
        }
    }

    const handleStop = () => {
        // setMinutes(0)
        setError('')
        setWorkout(null)
    }

    return (
        <div className="grid-x">
            {
                !workOut ?
                    <div className="cell small-8 medium-6 large-4 verticalCenter dialogContainer">
                        <div style={{ opacity: '1 !important', width: '100%' }}>
                            <div style={{ marginBottom: '21px' }}>
                                <h1 className="show-for-small-only">Configure Sequence</h1>
                                <h3 className="show-for-medium">Configure Sequence</h3>
                            </div>

                            <Toggle label="Use Dumbells?" id="includeDumbbells" handleChange={handleDumbbell} value={dumbBell} />
                            {/* <Toggle label="Include Kettle Balls?" id="includeKettleBalls" handleChange={handleKettleball} value={kettleBall} /> */}
                            {/* <Toggle label="Include Pull Up Bar?" id="includePullUpBar" /> */}
                            {/* <Toggle label="Include Running?" id="includeRunning" /> */}
                            <Toggle label="Timed Sequence?" id="timedSequence" handleChange={handleTimed} value={timed}></Toggle>
                            <button type="button" id="btnStart" className="button primary radius small" style={{ minWidth: '75px', marginRight: '7px' }} onClick={handleGo}>
                                Go {isLoading ?
                                    <BsArrowRepeat className="spinning" style={{ position: 'relative', top: '3px' }} />
                                    :
                                    <FaAngleDoubleRight style={{ position: 'relative', top: '3px' }} />
                                }
                            </button>
                            <div style={{ marginTop: '7px', minHeight: '0px', color: '#990000' }}>{errMessage}</div>
                        </div>
                    </div>
                    :
                    <div className="cell small-12 medium-6 large-4 verticalTop dialogContainer" >
                        <div style={{ opacity: '1 !important', width: '100%' }}>
                            <WorkOutDetail data={workOut} minutes={minutes} handleSkip={handleGo} handleStop={handleStop} />
                        </div>
                    </div>
            }

        </div >

    )
}

export default Landing